@import 'palette';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ubo-request-form-primary: mat-palette($default-primary);
$ubo-request-form-accent: mat-palette($default-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ubo-request-form-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ubo-request-form-theme: mat-light-theme((
  color: (
    primary: $ubo-request-form-primary,
    accent: $ubo-request-form-accent,
    warn: $ubo-request-form-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ubo-request-form-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.full-width {
  width: 100%;
}

.height-100 {
  height: 100%;
}

$sizes: 25, 50, 100;
@each $size in $sizes {
  .width-#{$size} {
    width: #{$size}#{"%"}
  }
}

.stepper-buttons {
  margin-top: $spacing * 10;
  margin-bottom: $spacing * 10;
}

.spacing-top {
  margin-top: $spacing * 5;
}
